<template>
  <LoadingComponent v-if="loading" />
  <div v-else class="day-summary">
    <table class="grid">
      <colgroup>
        <col width="22px" />
        <col width="68px" />
        <col width="60px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="33px" />
        <col width="25px" />
        <col width="25px" />
        <col width="25px" />
        <col width="70px" />
      </colgroup>
      <tr>
        <th>H</th>
        <th>Lead</th>
        <th>Intervenants</th>
        <th title="code postal">CP</th>
        <th>Trajet</th>
        <th>RDV</th>
        <th class="grey-header" title="Total d'heures">T(h)</th>
        <th class="blue-header" title="Soufflé">S</th>
        <th class="blue-header" title="Déroulé">D</th>
        <th class="blue-header" title="Rampant">R</th>
        <th class="blue-header" title="Mur">M</th>
        <th class="blue-header" title="Plafond">Pl</th>
        <th class="blue-header" title="Pignon">Pi</th>
        <th class="blue-header" title="Vide sanitaire">VS</th>
        <th class="blue-header" title="ITE">ITE</th>
        <th class="blue-header" title="ITI+">ITI+</th>
        <th class="grey-header" title="Total des surfaces">T(m²)</th>
        <th class="icon-wrapper w14" title="Dossier">
          <font-awesome-icon class="icon" :icon="['fas', 'file-alt']" />
        </th>
        <th class="icon-wrapper w15" title="Photo">
          <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
        </th>
        <th class="icon-wrapper w15 copy" :title="confirmation.title">
          <font-awesome-icon
            class="icon"
            :class="{ rotating: copyLoading }"
            :icon="confirmation.icon"
            @mouseleave="hover"
            @mouseenter="hover"
            @click="copyToClipboard(day)"
          />
        </th>
        <th>Stade</th>
      </tr>
      <tr
        v-for="row in computedDay.dayRows"
        :key="row.id"
        :class="{
          off: !row.active,
          disabled: row.lead && row.lead.disabled === 1,
          simulatable: row.simulatable
        }"
      >
        <td class="light-grey-column">
          {{ row.lead ? row.lead.hour_previsite : '' }}
          <div
            v-if="row.simulatable && simulated"
            class="action"
            @click="resetSimulation"
          >
            <font-awesome-icon class="icon" icon="minus-square" />
          </div>
        </td>
        <td class="commentable">
          <TooltipComment
            v-if="row.lead"
            placement="right-start"
            :lead="row.lead"
            :canEdit="canEdit"
          >
            <TooltipLink
              :name="row.lead.title_contact"
              :dealId="row.lead.project_id"
              :lead="row.lead"
              @changeSav="refreshCPlanning"
            >
              <div class="title-container">
                <ColorTag
                  :color="
                    row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]
                  "
                  :style="{
                    left: '2px',
                    bottom: '2px',
                    top: '2px',
                    width: '10px',
                    'border-radius': '2px'
                  }"
                />
                <span
                  v-if="row.lead.comments && row.lead.comments.count"
                  class="comment-tag"
                ></span>
                <span>{{ row.lead.title_contact }}</span>
              </div>
            </TooltipLink>
          </TooltipComment>
        </td>
        <td
          class="text-left pl-1 pr-1"
          :title="row.lead ? row.lead.title_agent : ''"
        >
          <TooltipEditIntervenants
            v-if="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :lead="row.lead"
          >
          </TooltipEditIntervenants>
          {{
            row.lead && row.lead.title_agent
              ? row.lead.title_agent.split(' ')[0]
              : ''
          }}
        </td>
        <td>
          {{ row.lead ? row.lead.zipCode : '' }}
          <input
            v-if="row.simulatable"
            :id="technician.id"
            type="number"
            :value="simulated ? simulation[row.index].zipCode : null"
            @change="handleInputChange($event, 'zipCode', row.index)"
          />
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_time_ite
              : row.lead
              ? row.lead.trajet_time_ite
              : null | time
          }}
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].pose_time_ite
              : row.lead
              ? row.lead.pose_time_ite
              : null | time
          }}
        </td>
        <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_pose_ite_h_sum
              : row.lead
              ? row.lead.trajet_pose_ite_h_sum
              : null | time
          }}
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_souffle > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="s"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_souffle > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_souffle }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_deroule > 0,
            editable: canEdit
          }"
        >
          {{ row.lead ? row.lead.surface_deroule : '' }}
          <TooltipEdit
            v-if="row.lead"
            field="d"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_deroule > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_deroule }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_rampant > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="r"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_rampant > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_rampant }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_mur > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="m"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_mur > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_mur }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_plafond > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="p"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_plafond > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_plafond }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_pignon > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="pi"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_pignon > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_pignon }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_vide_sanitaire > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="vs"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_vide_sanitaire > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_vide_sanitaire }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_ite > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="ite"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_ite > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_ite }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_iti > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="iti"
            :weekId="week_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_iti > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <span class="value">{{ row.lead.surface_iti }}</span>
            </div>
          </TooltipEdit>
        </td>
        <td>
          {{ row.lead ? row.lead.total : '' }}
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="dossier[row.id]"
            @change="handleCheckboxChange('dossier', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="photo[row.id]"
            @change="handleCheckboxChange('photo', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="confirmation_previsite[row.id]"
            @change="handleCheckboxChange('confirmation_previsite', row)"
          />
        </td>
        <td class="editable">
          <TooltipPipeLine
            v-if="row.lead"
            :lead="row.lead"
            placement="auto"
            :canEdit="canEdit && !row.lead.disabled"
          >
            <Stage v-if="row.lead" :stage_id="row.lead.cstage_id" />
          </TooltipPipeLine>
          <b-form-checkbox
            v-else-if="canEdit && !computedDay.optimised"
            switch
            :checked="row.active"
            @change="handleSwitchChange(row)"
          ></b-form-checkbox>
        </td>
      </tr>
      <tr>
        <th rowspan="3" class="off"></th>
        <th colspan="3">CA</th>
        <th>Trajet</th>
        <th>RDV</th>
        <th>T(h)</th>
        <td class="light-grey-column">
          {{ computedDay.total_s_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_d_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_r_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_m_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_p_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_pignon_planif }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_vs_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.ite_day }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.iti_day }}
        </td>
        <td>
          {{ computedDay.total_total_m_planifie }}
        </td>
        <th colspan="3">Planifié</th>
        <th
          rowspan="2"
          class="action"
          :class="{ optimised: computedDay.optimised }"
          @click="toggleOptimised"
        >
          {{ computedDay.optimised ? 'Optimisé' : 'Non optimisé' }}
        </th>
      </tr>
      <tr>
        <td colspan="3">
          {{ computedDay.ca_d }}
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_trajet
                : computedDay.total_trajet | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated ? simulation.total_pose : computedDay.total_pose | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_total_h
                : computedDay.total_total_h | time
            }}
          </span>
        </td>
        <td colspan="9" class="light-grey-column"></td>
        <td colspan="4" class="color"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  name: 'DaySummary',
  components: {
    LoadingComponent,
    TooltipPipeLine: () => import('@/components/ui/TooltipPipeLine'),
    TooltipComment: () => import('@/components/ui/TooltipComment'),
    TooltipLink: () => import('@/components/ui/TooltipLink'),
    TooltipEdit: () => import('@/components/ui/TooltipEdit'),
    ColorTag: () => import('@/components/ui/ColorTag'),
    Stage: () => import('@/components/Stage'),
    TooltipEditIntervenants: () =>
      import('@/components/ui/TooltipEditIntervenants')
  },
  props: {
    day: {
      required: true
    },
    technician: {
      required: true
    },
    week_id: {
      required: true
    },
    depot_zipCode: {
      required: false
    },
    user_zipCode: {
      required: false
    },
    canEdit: {
      default: false
    },
    loading: {
      default: false
    }
  },
  data() {
    return {
      confirmation: {
        icon: ['fas', 'user-tie'],
        title: 'Confirmé'
      },
      dossier: [],
      photo: [],
      confirmation_previsite: [],
      simulation: {
        1: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        2: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        3: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        4: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        5: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0
      },
      simulated: false,
      copyLoading: false
    }
  },
  methods: {
    ...mapActions([
      'updateFieldOptimiseCPlanning',
      'changeCEEPhotoMeetingCommercial',
      'updateRowStatusCPlanning',
      'getSimulatedTrajetCPlanning',
      'fetchCPlanning',
      'getContactPhone'
    ]),
    hover(e) {
      if (e.target.classList[2] == 'fa-user-tie') {
        this.confirmation.icon = ['fas', 'copy']
        this.confirmation.title = 'Copier'
      }
      if (e.target.classList[2] == 'fa-copy') {
        this.confirmation.icon = ['fas', 'user-tie']
        this.confirmation.title = 'Confirmé'
      }
    },
    async copyToClipboard(day) {
      try {
        if (this.copyLoading) {
          return
        }
        this.copyLoading = true
        let texts = []
        if (day.leads && day.leads.length) {
          for (let index = 0; index < day.leads.length; index++) {
            const lead = day.leads[index]

            const date = moment(lead.date_previsite).format('dddd D MMMM')
            const startHoure = lead.hour_previsite
            const endHoure = moment(startHoure, 'HH[h]')
              .add(lead.trajet_pose_ite_h_sum, 'minutes')
              .format('HH[h]mm')

            let phones = await this.getContactPhone(lead.contact_id)
            const phone = phones.join(' / ')

            const commercial = lead.title_comm_ite
              ? lead.title_comm_ite
              : lead.title_comm_terrain

            let surfaces = ''
            if (lead.surface_souffle > 0) {
              surfaces += 'Soufflé: ' + lead.surface_souffle + 'm²\n'
            }
            if (lead.surface_deroule > 0) {
              surfaces += 'Déroulé: ' + lead.surface_deroule + 'm²\n'
            }
            if (lead.surface_rampant > 0) {
              surfaces += 'Rampant: ' + lead.surface_rampant + 'm²\n'
            }
            if (lead.surface_mur > 0) {
              surfaces += 'Mur: ' + lead.surface_mur + 'm²\n'
            }
            if (lead.surface_plafond > 0) {
              surfaces += 'Plafond: ' + lead.surface_plafond + 'm²\n'
            }
            if (lead.surface_pignon > 0) {
              surfaces += 'Pignon: ' + lead.surface_pignon + 'm²\n'
            }
            if (lead.surface_vide_sanitaire > 0) {
              surfaces +=
                'Vide sanitaire: ' + lead.surface_vide_sanitaire + 'm²\n'
            }
            if (lead.surface_ite > 0) {
              surfaces += 'ITE: ' + lead.surface_ite + 'm²\n'
            }
            if (lead.surface_iti > 0) {
              surfaces += 'ITI+: ' + lead.surface_iti + 'm²\n'
            }

            texts.push(
              `Prévisite: ${date} entre ${startHoure}-${endHoure}
Previsteur/Commercial: ${commercial}
Client: ${lead.title_contact}
${lead.prj_adresse}
${lead.zipCode} ${lead.prj_ville}
${lead.precarite ? lead.precarite + ' - ' : ''}${lead.type_heating}
${surfaces}Numéro de téléphone: ${phone}
Informations complémentaire:`
            )
            if (index >= 0 && index < day.leads.length - 1) {
              texts.push('\n***********************************************\n')
            }
          }
        }
        const text = texts.join('')

        const el = document.createElement('textarea')
        el.value = text
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        el.setSelectionRange(0, 99999) /*For mobile devices*/
        document.execCommand('copy')
        document.body.removeChild(el)

        this.$bvToast.toast(text, {
          title: 'Texte copié',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
        this.copyLoading = false
      } catch (error) {
        this.copyLoading = false
      }
    },
    toggleOptimised() {
      if (
        this.currentUser.isSuper ||
        (this.canEdit &&
          (this.currentUser.type === 'user.admin' ||
            this.currentUser.type === 'user.resp.plan')) ||
        (this.currentUser.id = 687)
      ) {
        this.updateFieldOptimiseCPlanning({
          day: this.computedDay
        })
      }
    },
    handleCheckboxChange(field, row) {
      if (field) {
        this.$nextTick(() => {
          this.changeCEEPhotoMeetingCommercial({
            lead: row.lead,
            field: field,
            value: row.lead[field] == 1 ? 0 : 1
          })
        })
      }
    },
    handleSwitchChange(row) {
      this.updateRowStatusCPlanning({
        row: row,
        day: this.computedDay
      })
    },
    async refreshCPlanning() {
      await this.fetchCPlanning()
    },
    handleInputChange(e, field, index) {
      this.simulation[index][field] = e.target.value
      this.$nextTick(async () => {
        this.simulated = true

        // Simulated trajet
        if (field == 'zipCode') {
          let origin = this.depot_zipCode
          if (this.user_zipCode) {
            origin = this.user_zipCode
          }
          if (
            index > 1 &&
            this.computedDay.dayRows[index - 2].lead &&
            this.computedDay.dayRows[index - 2].lead.zipCode
          ) {
            origin = this.computedDay.dayRows[index - 2].lead.zipCode
          }
          this.simulation[index][
            'trajet_time_ite'
          ] = await this.getSimulatedTrajetCPlanning({
            origin: origin,
            destination: this.simulation[index].zipCode
          })
        }

        // Total simulated trajet
        if (this.simulation[index]['trajet_time_ite']) {
          this.simulation[index]['trajet_pose_ite_h_sum'] += this.simulation[
            index
          ]['trajet_time_ite']
          this.simulation['total_trajet'] =
            this.computedDay.total_trajet +
            this.simulation[index]['trajet_time_ite']
        }

        // Total simulated RDV
        this.simulation[index]['pose_time_ite'] = 60
        this.simulation[index]['trajet_pose_ite_h_sum'] += this.simulation[
          index
        ]['pose_time_ite']
        this.simulation['total_pose'] =
          this.computedDay.total_pose + this.simulation[index]['pose_time_ite']

        // Total simulated Total hours
        this.simulation['total_total_h'] =
          this.computedDay.total_total_h +
          this.simulation[index]['trajet_pose_ite_h_sum']
      })
    },
    resetSimulation() {
      this.simulation = {
        1: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        2: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        3: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        4: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        5: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0
      }
      this.simulated = false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'getPlanningPipeline']),
    colorStadeIte: function() {
      let status = []
      for (let index = 0; index < this.computedDay.dayRows.length; index++) {
        const element = this.computedDay.dayRows[index]
        if (
          element.lead &&
          element.lead.cpipeline_id &&
          element.lead.cstage_id &&
          element.lead.csubstage_id
        ) {
          this.getPlanningPipeline.forEach(pipeline => {
            if (pipeline.id == element.lead.cpipeline_id) {
              pipeline.stages.forEach(stages => {
                if (stages.id == element.lead.cstage_id) {
                  stages.csubstages.data.filter(substage => {
                    if (substage.id == element.lead.csubstage_id) {
                      status[element.index] = substage.color
                    }
                  })
                }
              })
            }
          })
        }
      }
      return status
    },
    computedDay: function() {
      if (this.day) {
        this.day.dayRows.forEach(row => {
          if (row.lead) {
            this.dossier[row.id] = row.lead.dossier
            this.photo[row.id] = row.lead.photo
            this.confirmation_previsite[row.id] =
              row.lead.confirmation_previsite
          }
        })
        return this.day
      }
      return {}
    }
  },
  filters: {
    time: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 10) return '00h0' + value
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    colorStatus: function(value) {
      switch (value) {
        case 'red':
          return 'redStatus'
        case 'orange':
          return 'orangeStatus'
        case 'yellow':
          return 'yellow'
        case 'green':
          return 'green'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.day-summary {
  table {
    &.grid {
      width: 649px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 9px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 1px;

          &.off {
            background-color: #9c9c9c;
            border-color: #8e8e8e;
          }
        }

        th {
          font-weight: 700;
          background-color: #ededed;
          &.blue-header {
            background-color: #2dabe2;
            color: #fff;
          }
          &.grey-header {
            background-color: #6c757d;
            color: #fff;
          }

          &.icon-wrapper {
            padding: 0 2px;
            &.w13 {
              font-size: 13px;
            }
            &.w14 {
              font-size: 14px;
            }
            &.w15 {
              font-size: 15px;
              &.copy {
                cursor: pointer;
              }
            }
            .icon {
              &.rotating {
                animation: rotating 1s linear infinite;
              }
            }
          }

          &.action {
            position: relative;
            padding: 0 2px;
            cursor: pointer;
            &.optimised {
              background-color: #2dabe2;
              color: #fff;
            }
          }
        }
        td {
          &.light-grey-column {
            background-color: #dbdbdb;
          }
          .planning-checkbox {
            padding-left: 27px;
          }
        }

        &.off {
          th,
          td {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }
        &.disabled {
          th,
          td {
            background-color: #dbdbdb;
            border-color: #dbdbdb;
          }
        }

        &.simulatable {
          td {
            position: relative;
            input {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              text-align: center;
            }

            .action {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #e73e01;
            }
          }
        }

        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
  }
  @keyframes rotating {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
}
</style>

<style lang="scss">
.day-summary {
  table {
    &.grid {
      td {
        .v-popover {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .trigger {
            display: block !important;
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        &.editable {
          position: relative;
          .edit {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 12px;
            justify-content: center;
            align-items: center;
            background: #dbdbdb;
            color: #2dabe2;
            z-index: 3;
            cursor: pointer;
          }

          .content {
            height: 100%;
            padding: 2px 2px 0;
            .info-surface {
              position: absolute;
              top: -7px;
              right: 1px;
              font-size: 7px;
              color: #2dabe2;
              z-index: 1;
            }
          }
          &:hover {
            .edit {
              display: flex;
            }
          }
        }

        &.commentable {
          position: relative;
          .v-popover {
            .trigger {
              .title-container {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-left: 15px;
                .comment-tag {
                  position: absolute;
                  width: 20px;
                  height: 10px;
                  background: #ffe436;
                  z-index: 9;
                  display: block;
                  top: -2px;
                  right: -7px;
                  transform: rotateZ(45deg);
                }
                span {
                  outline: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        &.colorable {
          .value {
            position: relative;
            z-index: 2;
          }
        }
      }
    }
    .color {
      font-weight: 700;
      background-color: #ededed;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2dabe2;
    background-color: #2dabe2;
  }
}
</style>
