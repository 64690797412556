<template>
  <div class="technician">
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[0]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[1]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[2]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[3]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[4]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[5]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :week_id="week_id"
        :day="computedData.days[6]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
  </div>
</template>

<script>
import DaySummary from '@/views/CPlanning/DaySummary.vue'

export default {
  name: 'PlanningRow',
  components: { DaySummary },
  props: {
    data: {
      required: true
    },
    week_id: {
      required: true
    },
    depot_zipCode: {
      required: true
    },
    canEdit: {
      default: false
    }
  },
  computed: {
    computedData: function() {
      if (this.data) {
        return this.data
      }
      return { loading: true }
    }
  }
}
</script>

<style lang="scss" scoped>
.technician {
  display: inline-flex;
  margin-bottom: 15px;
  .day {
    display: block;
    text-align: center;
    width: 649px;
    height: 200px;
    padding: 10px 0;
    margin-right: 5px;
    font-size: 10px;
    font-weight: bold;
    // border: 1px solid rgba(0, 0, 0, 0.08);
    // background-color: #f6f6f6;
    color: #656565;

    box-sizing: border-box;
    &.week {
      margin-right: 20px;
      min-width: 280px;
      box-shadow: 4px 0px 6px 0px #dadada;
      z-index: 2;
      position: sticky;
      left: 0;
    }
  }
}
</style>
