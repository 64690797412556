<template>
  <LoadingComponent v-if="loading" />
  <div v-else class="day-full-summary">
    <table class="grid">
      <colgroup>
        <col width="22px" />
        <col width="68px" />
        <col width="60px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="33px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="33px" />
        <col width="25px" />
        <col width="50px" />
        <col width="70px" />
      </colgroup>
      <tr>
        <th rowspan="2" class="off"></th>
        <th colspan="3">CA</th>
        <th>Trajet</th>
        <th>RDV</th>
        <th>T(h)</th>
        <td class="light-grey-column">
          {{ computedDayStat.fd_s_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_d_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_r_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_m_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_pl_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_pi_cplanifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_vs_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_ite_planifie }}
        </td>
        <td class="light-grey-column">
          {{ computedDayStat.fd_iti_planifie }}
        </td>
        <td>
          {{ computedDayStat.fd_planifie }}
        </td>
        <th colspan="2">Planifié</th>
        <th rowspan="2">
          Stat.<br />
          Jour.
        </th>
      </tr>
      <tr>
        <td colspan="3">
          {{ computedDayStat.fd_ca }}
        </td>
        <td>
          <span>
            {{ computedDayStat.fd_h_trajet | time }}
          </span>
        </td>
        <td>
          <span>
            {{ computedDayStat.fd_h_pose | time }}
          </span>
        </td>
        <td>
          <span>
            {{ computedDayStat.fd_h | time }}
          </span>
        </td>
        <td colspan="9" class="light-grey-column"></td>
        <td colspan="3" class="color"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
export default {
  name: 'DayStatRow',
  components: {
    LoadingComponent
  },
  data() {
    return {}
  },
  props: {
    loading: {
      default: false
    },
    stat: {
      required: true
    }
  },
  computed: {
    computedDayStat: function() {
      if (this.stat) {
        return this.stat
      }
      return {}
    }
  },
  filters: {
    time: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.day-full-summary {
  table {
    &.grid {
      width: 649px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 9px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 1px;

          &.off {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }

        th {
          font-weight: 700;
          background-color: #ededed;
        }

        td {
          &.light-grey-column {
            background-color: #dbdbdb;
          }
        }

        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
    .color {
      font-weight: 700;
      background-color: #ededed;
    }
  }
}
</style>
