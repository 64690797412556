<template>
  <div class="week-summary">
    <LoadingComponent v-if="data.loading" />
    <table v-else class="grid">
      <tr>
        <th colspan="11">{{ data.name }}</th>
      </tr>
      <tr>
        <th></th>
        <th class="blue-header" title="Soufflé">S</th>
        <th class="blue-header" title="Déroulé">D</th>
        <th class="blue-header" title="Rampant">R</th>
        <th class="blue-header" title="Mur">M</th>
        <th class="blue-header" title="Plafond">Pl</th>
        <th class="blue-header" title="Pignon">Pi</th>
        <th class="blue-header" title="Vide sanitaire">VS</th>
        <th class="blue-header" title="ITE">ITE</th>
        <th class="blue-header" title="ITI+">ITI+</th>
        <th class="grey-header" title="Total des surfaces">T(m²)</th>
      </tr>
      <tr>
        <th>Planifié</th>
        <td class="light-grey-column">{{ data.s_planifie }}</td>
        <td class="light-grey-column">{{ data.d_planifie }}</td>
        <td class="light-grey-column">{{ data.r_planifie }}</td>
        <td class="light-grey-column">{{ data.m_planifie }}</td>
        <td class="light-grey-column">{{ data.p_planifie }}</td>
        <td class="light-grey-column">{{ data.pignon_planif }}</td>
        <td class="light-grey-column">{{ data.vs_planifie }}</td>
        <td class="light-grey-column">{{ data.total_ite_planifie }}</td>
        <td class="light-grey-column">{{ data.total_iti_planifie }}</td>
        <td>{{ data.total_planifie_w }}</td>
      </tr>

      <tr>
        <th colspan="1">T(m²)</th>
        <th colspan="4">Trajet</th>
        <th colspan="5">RDV</th>
        <th>T(h)</th>
      </tr>
      <tr>
        <td>
          {{ data.total_m_w }}
        </td>
        <td colspan="4">
          {{ data.total_trajet_w | time }}
        </td>
        <td colspan="5">
          {{ data.total_pose_w | time }}
        </td>
        <td>
          {{ data.total_pose_trajet_w | time }}
        </td>
      </tr>
      <tr>
        <th colspan="5">Chiffre d'affaire</th>
        <td colspan="6">
          {{ data.ca_w }}
        </td>
      </tr>
      <colgroup>
        <col width="39px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="21px" />
        <col width="22px" />
        <col width="32px" />
      </colgroup>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
export default {
  name: 'WeekSummary',
  props: {
    data: {
      required: true
    }
  },
  components: { LoadingComponent },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['getCPlanning'])
  },
  filters: {
    time: function(value) {
      value = parseFloat(value)
      if (!value) return '00h00'
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.week-summary {
  display: block;
  text-align: center;
  height: 215px;
  width: 280px;
  padding: 10px;
  transition: 0.2s;
  transition-timing-function: ease-out;
  table {
    &.grid {
      width: 261px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 9px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 2px;
        }

        th {
          background-color: #ededed;
          font-weight: 700;
          &.blue-header {
            background-color: #2dabe2;
            color: #fff;
          }
          &.grey-header {
            background-color: #6c757d;
            color: #fff;
          }
        }
        td {
          &.light-grey-column {
            background-color: #dbdbdb;
          }
        }

        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
  }
}
</style>
