<template>
  <div class="page-content">
    <div class="header">
      <div
        class="logo"
        :class="{ default: !computedPlanning.filial_img }"
        :style="{
          'background-image': computedPlanning.filial_img
            ? `url(${computedPlanning.filial_img})`
            : 'none'
        }"
      ></div>
      <form @submit.prevent="hundleSubmit">
        <div class="filters">
          <div class="deposit-number">
            {{
              computedPlanning.depot_nbr ? computedPlanning.depot_nbr : '- -'
            }}
          </div>
        </div>
        <div class="filters">
          <b-form-group
            label="Filiale"
            label-for="filiale-input"
            class="planning-filtres"
          >
            <b-form-select
              id="filiale-input"
              v-model="selectedFiliale"
              @change="hundleFilialeChange"
              :options="getAvailableFiliales"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableFiliales || !getAvailableFiliales.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucune filiale disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Dépôt"
            label-for="deposit-input"
            class="planning-filtres"
          >
            <b-form-select
              id="deposit-input"
              v-model="selectedDeposit"
              :options="getAvailableDeposits"
              @change="hundleChangeFilter"
              value-field="id"
              text-field="name"
              required
            >
              <template
                v-slot:first
                v-if="!getAvailableDeposits || !getAvailableDeposits.length"
              >
                <b-form-select-option :value="null" disabled
                  >-- Aucun dépôt disponible --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </div>

        <div class="filters date">
          <b-form-group
            label="Année"
            label-for="year-input"
            class="planning-filtres"
          >
            <b-form-select
              id="year-input"
              v-model="selectedYear"
              @change="hundleYearChange"
              :options="getAvailableYears"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            v-if="selectedYear"
            label="Semaine"
            label-for="week-input"
            class="planning-filtres"
          >
            <b-form-select
              id="week-input"
              v-model="selectedWeek"
              :options="getAvailableWeeks"
              @change="hundleChangeFilter"
              value-field="id"
              text-field="week_nbr"
              required
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="filters">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleRefresh"
            :disabled="getCPlanningProcessing"
          >
            <font-awesome-icon class="icon" icon="sync-alt" />
          </b-button>
          <b-spinner
            v-if="getCPlanningProcessing"
            :style="{ opacity: getCPlanningProcessing ? 1 : 0 }"
            label="Loading..."
          ></b-spinner>
        </div>
        <div class="filters">
          <div v-if="computedErrors" class="error">
            <ul>
              <li v-for="(error, index) in computedErrors" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
    <div
      class="content"
      :class="{ minimised: minimised, 'day-stat-on': minimisedDayStat }"
    >
      <div class="days">
        <div class="day week">
          Commerciaux
          <div class="icon-wrapper" @click="minimised = !minimised">
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-left']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.monday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.tuesday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.wednesday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.thursday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.friday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.saturday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
        <div class="day">
          {{ computedPlanning.sunday }}
          <div
            class="icon-wrapper-day"
            @click="minimisedDayStat = !minimisedDayStat"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'caret-square-down']"
            />
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="week-column">
          <div class="empty-stat-day">
            <div class="content"></div>
          </div>
          <WeekSummary
            v-for="t in computedPlanning.commercials"
            :key="'w' + t.id"
            :data="t"
          />
        </div>
        <div class="days-column">
          <DayStatRow
            :data="computedPlanning.dayStat"
            class="days-column-stat-row"
          />
          <PlanningRow
            v-for="t in computedPlanning.commercials"
            :key="'d' + t.id"
            :data="t"
            :canEdit="canEdit"
            :week_id="computedPlanning.week_id"
            :depot_zipCode="computedPlanning.depot_zipCode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanningRow from '@/views/CPlanning/PlanningRow.vue'
import WeekSummary from '@/views/CPlanning/WeekSummary.vue'
import DayStatRow from '@/views/CPlanning/DayStatRow.vue'
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')

export default {
  name: 'CPlanning',
  components: {
    PlanningRow,
    WeekSummary,
    DayStatRow
  },
  data() {
    return {
      selectedYear: null,
      selectedWeek: null,
      selectedFiliale: null,
      selectedDeposit: null,
      canEdit: false,
      localError: null,
      minimised: false,
      minimisedDayStat: false
    }
  },
  methods: {
    ...mapActions([
      'fetchFiliales',
      'fetchDeposits',
      'fetchYears',
      'fetchWeeks',
      'initCPlanning',
      'fetchPipeline',
      'fetchCPlanning'
    ]),
    hundleYearChange() {
      this.selectedWeek = null
      this.fetchWeeks(this.selectedYear)
      if (!this.selectedWeek) {
        const localError = []
        localError.push('Le numéro de la semaine est obligatoire')
        this.localError = localError
        return
      }
    },
    async hundleFilialeChange() {
      this.selectedDeposit = null
      this.fetchDeposits(this.selectedFiliale)
    },
    hundleChangeFilter() {
      this.localError = null
      if (this.selectedDeposit && this.selectedWeek) {
        this.$router
          .replace(
            '/planning-commerciaux/depot/' +
              this.selectedDeposit +
              '/semaine/' +
              this.selectedWeek
          )
          .catch(() => {})
      }
      this.hundleSubmit()
    },
    checkForPermission() {
      if (this.getAvailableDeposits) {
        this.getAvailableDeposits.forEach(d => {
          if (d.id == this.selectedDeposit) {
            this.canEdit = d.can_edit || this.currentUser.isSuper ? true : false
          }
        })
      }
    },
    async hundleSubmit() {
      if (!this.selectedDeposit || !this.selectedWeek) {
        const localError = []
        if (!this.selectedDeposit) {
          localError.push('Le dépôt est obligatoire')
        }
        if (!this.selectedWeek) {
          localError.push('Le numéro de la semaine est obligatoire')
        }
        this.localError = localError
        return
      }
      if (this.selectedYear && this.selectedDeposit && this.selectedWeek) {
        await this.initCPlanning({
          depot_id: this.selectedDeposit,
          week_id: this.selectedWeek
        })
        if (this.getCPlanning) {
          if (this.selectedYear != this.getCPlanning.year) {
            this.selectedYear = this.getCPlanning.year
            await this.fetchWeeks(this.selectedYear)
            this.selectedWeek = this.getCPlanning.week_id
          }
        }
        this.checkForPermission()
        await this.fetchCPlanning()
        this.minimisedDayStat = true
        setTimeout(() => {
          this.minimisedDayStat = false
        }, 10000)
      }
    },
    async handleRefresh() {
      if (this.selectedDeposit && this.selectedWeek) {
        await this.initCPlanning({
          depot_id: this.selectedDeposit,
          week_id: this.selectedWeek
        })

        await this.fetchCPlanning()
        this.minimisedDayStat = true
        setTimeout(() => {
          this.minimisedDayStat = false
        }, 10000)
      } else {
        this.filterDefaultUrl()
      }
    },
    async fetchPlanningCommerciaux() {
      this.checkForPermission()
      /*  ~2) GET PIPELINE */
      if (!this.getPlanningPipeline || !this.getPlanningPipeline.length) {
        this.fetchPipeline()
      }
      /* ~3) FETCH PLANNINGS PER TECHNICIAN */
      await this.fetchCPlanning()
      this.minimisedDayStat = true
      setTimeout(() => {
        this.minimisedDayStat = false
      }, 10000)
    },
    async filterParamsUrl() {
      for (let index = 0; index < this.getAvailableFiliales.length; index++) {
        const filiales = this.getAvailableFiliales[index]
        filiales.depots.data.forEach(depots => {
          if (depots.id == this.$route.params.id_depot) {
            // select default filiale
            this.selectedFiliale = filiales.id
            // select default deposit
            this.selectedDeposit = this.$route.params.id_depot
          }
        })
      }
      await this.fetchDeposits(this.selectedFiliale)
      /* ~2) GET commercials AND GLOBAL INFORMATIONS */
      this.initCPlanning({
        depot_id: this.selectedDeposit,
        week_id: this.$route.params.id_week
      }).then(async () => {
        if (this.getCPlanning) {
          this.selectedYear = this.getCPlanning.year
          this.selectedWeek = this.getCPlanning.week_id
        }
        // select default week
        this.getAvailableWeeks.forEach(week => {
          if (week.id == this.$route.params.id_week) {
            this.selectedWeek = week.id
            this.currentYear = week.year
          }
        })
        // get weeks of current year
        if (this.currentYear != this.selectedYear) {
          await this.fetchWeeks(this.selectedYear)
          this.getAvailableWeeks.forEach(week => {
            if (week.id == this.$route.params.id_week) {
              this.selectedWeek = week.id
            }
          })
        }
        this.fetchPlanningCommerciaux()
      })
    },
    async filterDefaultUrl() {
      // select default filiale
      if (this.getAvailableFiliales && this.getAvailableFiliales.length) {
        this.selectedFiliale = this.getAvailableFiliales[0].id
      }
      // get deposits
      await this.fetchDeposits(this.selectedFiliale)
      // select default deposit
      if (this.getAvailableDeposits[0]) {
        this.selectedDeposit = this.getAvailableDeposits[0].id
      }
      // get current year
      this.selectedYear = moment().year()
      // get currentWeek
      const currentWeek = moment().week()
      // select current week
      this.getAvailableWeeks.forEach(week => {
        if (week.week_nbr == currentWeek) {
          this.selectedWeek = week.id
          this.currentYear = week.year
        }
      })
      // get weeks of current year
      if (this.currentYear != this.selectedYear) {
        await this.fetchWeeks(this.selectedYear)
        this.getAvailableWeeks.forEach(week => {
          if (week.week_nbr == currentWeek) {
            this.selectedWeek = week.id
          }
        })
      }
      this.$router
        .replace(
          '/planning-commerciaux/depot/' +
            this.selectedDeposit +
            '/semaine/' +
            this.selectedWeek
        )
        .catch(() => {})
      /* ~2) GET commercials AND GLOBAL INFORMATIONS */
      this.initCPlanning({
        depot_id: this.selectedDeposit,
        week_id: this.selectedWeek
      }).then(async () => {
        if (this.getCPlanning) {
          this.selectedYear = this.getCPlanning.year
          this.selectedWeek = this.getCPlanning.week_id
        }
        this.fetchPlanningCommerciaux()
      })
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getCPlanning',
      'getAvailableFiliales',
      'getAvailableDeposits',
      'getAvailableYears',
      'getAvailableWeeks',
      'getCPlanningError',
      'getCPlanningProcessing',
      'getPlanningPipeline'
    ]),
    computedErrors: function() {
      let errors = null
      if (this.localError || this.getCPlanningError) {
        errors = []
        if (this.localError && this.localError.length) {
          errors = errors.concat(this.localError)
        }
        if (this.getCPlanningError && this.getCPlanningError.length) {
          errors = errors.concat(this.getCPlanningError)
        }
        if (this.selectedDeposit && this.selectedWeek) {
          errors = []
        }
      }
      return errors
    },
    computedPlanning: function() {
      if (this.getCPlanning) {
        return this.getCPlanning
      }
      return { commercials: [] }
    }
  },
  watch: {
    $route() {
      if (!this.$route.params.id_depot && !this.$route.params.id_week) {
        this.filterDefaultUrl()
      }
    }
  },
  async mounted() {
    /* 1) GET FILTERS PARAMS */
    // get filiales
    if (!this.getAvailableFiliales || !this.getAvailableFiliales.length) {
      await this.fetchFiliales()
    }
    // get current year
    await this.fetchYears()
    if (this.$route.params.id_depot && this.$route.params.id_week) {
      this.filterParamsUrl()
    } else {
      this.filterDefaultUrl()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  display: block;
  background-color: #fff;
  height: 100%;

  .header {
    display: flex;
    padding: 0 4px;
    height: 75px;
    font-size: 11px;
    align-items: center;
    background: #f6f6f6;
    box-shadow: 0px 0px 8px 3px rgb(218, 218, 218);

    .logo {
      position: relative;
      width: 70px;
      height: 70px;
      margin: 3px 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.default {
        background: #dbdbdb;
      }
    }

    form {
      display: flex;
      align-items: center;
      height: 100%;

      .filters {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 700;

        .deposit-number {
          border: 2px solid #656565;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          color: #2dabe2;
          margin-left: 6px;
        }

        .error {
          color: #ec2f1c;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1rem;
          margin-top: 5px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .head-stat {
      text-align: center;
      font-weight: 700;
      margin: 0 15px;
      color: #383e42;
      .main-stat {
        display: flex;
        justify-content: center;
        position: relative;
        white-space: nowrap;
        .value {
          font-size: 35px;
        }
        .unit {
          font-size: 14px;
          display: block;
          position: relative;
          margin-top: -5px;
        }
      }
      .sub-stat {
        font-size: 14px;
        margin-top: 6px;
        white-space: nowrap;
        font-style: italic;
      }
    }
  }

  .content {
    position: relative;
    display: block;
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 5px;
    overflow: auto;

    .days {
      display: inline-flex;
      position: sticky;
      z-index: 11;
      top: 0;
      transition: 0.5s;
      transition-timing-function: ease-out;
      .day {
        display: block;
        position: sticky;
        left: 50px;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.08);
        background-color: #f6f6f6;
        color: #656565;
        height: 24px;
        width: 649px;
        margin-right: 5px;
        box-shadow: 0px 5px 6px 0px #dadada;
        box-sizing: border-box;

        &.week {
          position: sticky;
          left: 0;
          margin-right: 20px;
          width: 280px;
          box-shadow: 5px 2px 6px 0px #dadada;
          z-index: 2;
        }

        .icon-wrapper,
        .icon-wrapper-day {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          font-size: 20px;
          padding: 1px 5px;
          cursor: pointer;
          transition: 0.5s;
          transition-timing-function: ease-out;
        }
      }
    }
    .content-wrapper {
      display: inline-flex;
      position: relative;
      min-height: calc(100% - 29px);
      transform: translateY(-71px);
      transition: 0.5s all ease;
      .week-column {
        display: block;
        position: sticky;
        box-shadow: 5px 0px 6px 0px #dadada;
        background-color: #fff;
        font-weight: bold;
        font-size: 10px;
        color: #656565;
        box-sizing: border-box;
        margin-right: 20px;
        z-index: 10;
        left: 0;
        transition: 0.5s;
        transition-timing-function: ease-out;

        .empty-stat-day {
          opacity: 0;
          display: block;
          text-align: center;
          height: 71px;
          width: 280px;
          padding: 10px;
          transition: 0.5s all ease;
          .content {
            background: #ededed;
            height: 100%;
            margin-top: 0;
          }
        }
      }
      .days-column {
        display: block;
        transition: 0.5s;
        transition-timing-function: ease-out;

        .days-column-stat-row {
          opacity: 0;
          transition: 0.5s all ease;
        }
      }
    }

    &.day-stat-on {
      .icon-wrapper-day {
        transform: rotate(180deg);
      }

      .days-column-stat-row,
      .empty-stat-day {
        opacity: 1 !important;
      }
      .content-wrapper {
        transform: translateY(0px);
      }
    }
    &.minimised {
      .days {
        transform: translateX(-251px);
        .day {
          .icon-wrapper {
            transform: rotate(180deg);
          }
          &.week {
            margin-right: 30px;
          }
        }
      }
      .content-wrapper {
        .week-column {
          transform: translateX(-251px);
          .week-summary {
            transform: rotate(90deg) translateY(-44px);
          }
        }
        .days-column {
          transform: translateX(-241px);
        }
      }
      .empty-stat-day {
        opacity: 0 !important;
      }
    }
  }
}
</style>

<style lang="scss">
.page-content {
  .filters {
    .form-group {
      display: flex;
      align-items: center;
      margin: 4px 10px 0px;
      &.planning-filtres {
        label {
          margin-bottom: 0;
          width: 58px;
          text-transform: uppercase;
          color: #656565;
          font-size: 10px;
          margin-right: 8px;
          text-align: right;
        }
        .custom-select {
          width: 150px;
          font-size: 10px;
          color: #656565;
          font-weight: 700;
          height: calc(1.5em + 0.75rem + 1px);
        }
      }
    }

    &.date {
      .form-group {
        &.planning-filtres {
          .custom-select {
            width: 70px;
          }
        }
      }
    }

    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
      margin: 6px 24px 0px;
    }

    button {
      margin: 4px 20px 0px;
      svg {
        margin-top: 4px;
      }
    }
  }
  .content {
    &.minimised {
      .content-wrapper {
        .week-column {
          .week-summary {
            table {
              tr {
                height: 37px;
                &:first-child {
                  th {
                    &:first-child {
                      transform: rotate(-180deg);
                      font-size: 13px;
                      padding: 0 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tooltip {
  z-index: 10001;
  opacity: 1;
}
</style>
